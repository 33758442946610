.JournalPage {
text-align: center;
height: 100vh;
background-color: LavenderBlush;
}

.jp-h1 {
text-align: center;
background-color: MistyRose;
  color: Black;
  font-size: 20px;
  padding: 10px 60px;
box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-top: 0px;
}