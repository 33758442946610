.top-bar {
  /* background-color: rgb(39,92,123); */
  background-color: #f5f5f5;
  padding: 4px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.top-bar .site-title {
  margin-right: 20px;
}

.top-bar .site-title:visited {
  color: #275c7b;
}

.top-bar .user {
  /*position: absolute; */
  align-items: right;
  top: 0;
  right: 0;
}

.top-bar .user .logout-button {
  margin: 4px;
}

.dropbtn {
  background-color: rgb(225,157,95);
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
  margin-right: 10px;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f5f5f5;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}


/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: antiquewhite;
  padding: 20px;
  width: 100%;
  position: relative;
  bottom: 0;
}

.sizing-div{
  max-height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
  margin-right: 15px;
}