.DailyPage {
  text-align: center;
  min-height: 100vh;
  background-color: #f5f5f5;
  position: relative;
}

.dp-h1 {
text-align: center;
background-color: #b07721;
  color: white;
  font-size: 20px;
  padding: 10px 60px;
box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-top: 0px;
}

.LinearDay {
  color: white;
  padding: 15px 32px;
  text-align: center;
  font-size: 16px;
  transition-duration: 0.4s;
  cursor: pointer;
}

.cards {
  padding: 10px;
}

.LinearDay:hover {
background-color: #eba53d;
  color: white;
}

.daily-table {
  text-align: left;
}

.page-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  justify-content: center;
  flex-wrap: wrap;
  padding: 20px;
  min-height: 100vh;
  position: relative;
}

/* Hero section styles */
.hero-container {
  display: flex;
  flex-direction: row;
  margin: 10px;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.daily-hero {
  background-color: #b07721;
  color: white;
  padding: 30px;
  text-align: center;
  max-width: 95%;
}

.daily-hero h1 {
  font-size: 1.7rem;
  margin-bottom: 20px;
}

.daily-hero p {
  font-size: 1rem;
  margin-bottom: 30px;
}