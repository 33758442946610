.AboutPage {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
}

.section {
  width: 80%;
  margin-bottom: 50px;
}

.section h2 {
  font-size: 1.8rem;
  margin-bottom: 20px;
}

.section p {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.logo-img2 {
  max-width: 250px;
  max-height: 100%;
  margin: 0;
}
