.login-wrapper { position: absolute; top: 20%; margin: 0 20%;
  background: white; 
padding: 20px;}
.login-wrapper h1 { text-align: center;
  background: white; }
.oauth-wrapper { margin: 1em 0;
  background: white; }

.login-wrap-wrapper {
  width: 100%;
  height: 100%;
  background-image: url(https://i.imgur.com/5kQFm5N.jpg);
}