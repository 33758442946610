.CareerPage {
text-align: center;
/*min-height: 100vh;*/
background-color: #f5f5f5;
position: relative;
}

.cp-h1 {
  text-align: center;
  background-color: #30968c;
  color: white;
  font-size: 20px;
  padding: 10px 60px;
  margin-top: 0px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  
}

.back {
  margin:auto;
  position: absolute;
  left: 20px;
}

.ShootingForward {
  background-color: #30968c;
  color: white;
  padding: 15px 32px;
  text-align: center;
  font-size: 16px;
  transition-duration: 0.4s;
  cursor: pointer;  
}

.ShootingForward:hover {
  background-color: #42cfc1;
  color: white;
}

.page-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding: 20px;
  height: 100%;
}

.cards {
  padding: 10px;
}

/* Hero section styles */
.hero-container {
  display: flex;
  flex-direction: row;
  margin: 10px;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.career-hero {
  background-color: #30968c;
  color: white;
  padding: 30px;
  text-align: center;
  max-width: 95%;
}

.career-hero h1 {
  font-size: 1.7rem;
  margin-bottom: 20px;
}

.career-hero p {
  font-size: 1rem;
  margin-bottom: 30px;
}